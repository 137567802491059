// Imports --------------------------------------------------------------------------------
import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import ReactDatetime from 'react-datetime';
import {
	Button,
	Card,
	Container,
	Row,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	UncontrolledTooltip,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import compileTableRows from 'utils/compileTableRows';
import Select from 'react-select';

import { handleRedirect, handleOptions } from 'utils/handles';
import moment from 'moment';

import Loading from 'components/General/Loading';
import { formatDate, formatDateAU } from 'utils/dateFormatter';
import { titleCase } from 'utils/stringFormatter';
import secureAPI from 'utils/secureAPI';
// End: Imports ----------------------------------------------------------------------------

// Global Variables ------------------------------------------------------------------------
const { SearchBar } = Search;

let courtFilter;
let areaFilter;
let regionFilter;
let courtroomFilter;

const pagination = paginationFactory({
	page: 1,
	alwaysShowAllBtns: true,
	showTotal: true,
	withFirstAndLast: true,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} results
		</span>
	),
	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
		<div className="dataTables_length" id="datatable-basic_length">
			<label>
				Show
				{
					<select
						name="datatable-basic_length"
						aria-controls="datatable-basic"
						className="form-control form-control-sm"
						onChange={e => onSizePerPageChange(e.target.value)}
					>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="30">30</option>
						<option value="40">40</option>
						<option value="50">50</option>
					</select>
				}
				entries.
			</label>
		</div>
	),
});

const getSortedDistinct = (array, key) => {
	const collator = new Intl.Collator([], { numeric: true });
	return [...new Set(array.filter(el => el[key]).map(el => el[key]))].sort((a, b) => collator.compare(a, b));
};

// End: Global Variables -------------------------------------------------------------------

// Court List Table Component --------------------------------------------------------------
const CourtListTable = props => {
	const [alert, setAlert] = useState([]);
	const [originalCourtlist, setOriginal] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [selectOptions, setSelectOptions] = useState({
		courts: {},
		areas: {},
		regions: {},
		courtrooms: {},
	});

	const [activeCourts, setActiveCourts] = useState([]);
	const [activeCourtrooms, setActiveCourtrooms] = useState([]);
	const [activeRegions, setActiveRegions] = useState([]);
	const [activeAreas, setActiveAreas] = useState([]);

	const [loading, setLoading] = useState(true);
	const [knowns, setKnowns] = useState([]);
	const [isLinking, setLinking] = useState(false);
	const [linkee, setLinkee] = useState({});
	const [linker, setLinker] = useState(false);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const tableRef = useRef();

	let history = useHistory();
	let location = useLocation();

	const NoDataIndication = () => (
		<Container fluid className="adapt">
			<Row>
				<Col className="text-center m-6">{loading ? <Loading /> : <div>No data available</div>}</Col>
			</Row>
		</Container>
	);

	const KPNoDataIndication = () => (
		<Row>
			<Col className="text-center m-1">
				<div>No data available</div>
			</Col>
		</Row>
	);

	const optionsFormatter = (cell, row) => {
		const dynamicButton = handleOptions(
			row['Match_final'],
			history,
			row['CLIENTID_COURT'],
			row['ReferralID'],
			row['CaseStatus'],
			props.baseRoute,
			row['FULL NAME'],
		);

		const EEIReferralOptions = {
			0: (
				<DropdownItem onClick={e => handleConfirmEEIReferralUpdate(e, 0)}>
					<i className="far fa-bookmark" style={{ margin: '0 10px 0 0' }}></i>
					Add EEIO Flag
				</DropdownItem>
			),
			1: (
				<DropdownItem onClick={e => handleConfirmEEIReferralUpdate(e, 1)}>
					<i className="fas fa-bookmark" style={{ margin: '0 10px 0 0' }}></i>
					Remove EEIO Flag
				</DropdownItem>
			),
		};

		const handleConfirmEEIReferralUpdate = (e, EEIRefFlag) => {
			setAlert(
				<ReactBSAlert
					info // change InfoIcon
					style={{ display: 'block', marginTop: '150px' }}
					title={EEIRefFlag === 0 ? 'Adding EEIO Flag...!' : 'Removing EEIO Flag...'}
					onConfirm={() => {}}
					showConfirm={false}
					closeOnCancel={false}
				></ReactBSAlert>,
			);

			secureAPI(
				{
					endpoint: 'EEIREFERRAL',
					method: 'POST',
				},
				{
					CLIENTID_COURT: row.CLIENTID_COURT,
					EEIFlag: EEIRefFlag,
					responseType: 'json',
				},
			)
				.then(response => {
					setAlert(
						<ReactBSAlert
							success
							style={{ display: 'block', marginTop: '150px' }}
							title={EEIRefFlag === 0 ? 'EEIO Flag Added!' : 'EEIO Flag Removed!'}
							onConfirm={() => {
								setAlert([]);
								window.location.reload(); // Change with only refreshing the data table and not the filters.
							}}
							onCancel={() => {
								setAlert([]);
								window.location.reload(); // Change with only refreshing the data table and not the filters.
							}}
							confirmBtnBsStyle="info"
							btnSize=""
						></ReactBSAlert>,
					);
				})
				.catch(err => console.log(err));
		};

		const EEIReferralButton =
			props.user.attributes['custom:eji_role'] === 'admin' && row.EarlyEducationInterventionFlag === 'Yes'
				? EEIReferralOptions[1]
				: EEIReferralOptions[0];

		const handleLinking = e => {
			e.preventDefault();
			if (row['CLIENTID_COURT'] !== row['CLIENTID_MAP']) {
				let known_link = knowns.find(el => el['CLIENTID_COURT'] === row['CLIENTID_COURT']) ?? {};
				setAlert(
					<ReactBSAlert
						danger
						style={{ display: 'block', marginTop: '150px' }}
						title="Person already linked!"
						onConfirm={() => setAlert([])}
						confirmBtnBsStyle="info"
						btnSize=""
					>
						<LinkingTables linkee={row} linker={known_link} />
					</ReactBSAlert>,
				);
			} else {
				setLinking(true);
				setLinkee(row);
			}
		};

		return props.user.attributes['custom:eji_role'] === 'admin' ? (
			<UncontrolledDropdown>
				<DropdownToggle className="btn-icon-only" size="sm" color="">
					<i className="fas fa-ellipsis-v"></i>
				</DropdownToggle>
				<DropdownMenu className="table-dropdown">
					{dynamicButton}
					<DropdownItem onClick={e => handleLinking(e)}>
						<i className="fas fa-arrows-alt-h" style={{ margin: '0 10px 0 0' }}></i>
						Link Known Person
					</DropdownItem>
					<DropdownItem
						onClick={e =>
							handleRedirect(
								e,
								{
									history: history,
									pathid: 'courtprofileupdate',
									baseRoute: props.baseRoute,
									parentName: 'Court List',
									parentUrl: location.pathname,
								},
								['advice', row['CLIENTID_COURT'], row['CLIENTID_MAP']],
							)
						}
					>
						<i className="fas fa-edit" style={{ margin: '0 10px 0 0' }}></i>
						Add Brief Advice
					</DropdownItem>
					<DropdownItem
						onClick={e =>
							handleRedirect(
								e,
								{
									history: history,
									pathid: 'courtprofileupdate',
									baseRoute: props.baseRoute,
									parentName: 'Court List',
									parentUrl: location.pathname,
								},
								['school', row['CLIENTID_COURT'], row['CLIENTID_MAP']],
							)
						}
					>
						<i className="fas fa-school" style={{ margin: '0 10px 0 0' }}></i>
						Add School Information
					</DropdownItem>
					{EEIReferralButton}
				</DropdownMenu>
			</UncontrolledDropdown>
		) : (
			<UncontrolledDropdown>
				<DropdownToggle className="btn-icon-only" size="sm" color="">
					<i className="fas fa-ellipsis-v"></i>
				</DropdownToggle>
				{dynamicButton !== null ? (
					<DropdownMenu className="table-dropdown">{dynamicButton}</DropdownMenu>
				) : (
					<DropdownMenu className="table-dropdown">
						<DropdownItem> No Action Available </DropdownItem>
					</DropdownMenu>
				)}
			</UncontrolledDropdown>
		);
	};

	const dateFormatter = (cell, row) => {
		return cell ? formatDate(cell.trim()) : cell;
	};

	const trimFormatter = (cell, row) => {
		return cell ? cell.trim() : cell;
	};

	const schoolExitIconFormatter = (cell, row) => {
		let exitSchool = row['Enrolled Exit Date'] ? true : false;

		return (
			<>
				{cell ? cell : ''} {exitSchool ? <i className="ni ni-user-run"> </i> : ''}
			</>
		);
	};

	const schoolExitFormatter = (cell, row) => {
		return row['Enrolled Exit Date'] ? 'Yes' : '';
	};

	const handleOnSelect = (row, isSelect) => {
		if (isSelect) {
			setLinker(row);
		}
	};

	const LinkingTables = data => {
		return (
			<>
				<Row>
					<Col>
						<ToolkitProvider
							data={[data.linkee]}
							keyField="CLIENTID_MAP"
							columns={[
								{
									dataField: 'FULL NAME',
									text: 'ACCUSED FULL NAME',
									formatter: trimFormatter,
								},
								{
									dataField: 'GENDER',
									text: 'Gender',
									formatter: trimFormatter,
								},
								{
									dataField: 'CONSOLIDATED_COURT',
									text: 'Date of Birth',
									formatter: trimFormatter,
								},
							]}
						>
							{props => {
								return (
									<div>
										<BootstrapTable
											id="linkee-table"
											classes="button-table"
											bootstrap4={true}
											bordered={true}
											{...props.baseProps}
										/>
									</div>
								);
							}}
						</ToolkitProvider>
					</Col>
				</Row>
				<Row>
					<Col>
						<i className="fas fa-angle-double-down" style={{ margin: '0 0 20px 0' }}></i>
					</Col>
				</Row>
				<Row>
					<Col>
						<ToolkitProvider
							data={[data.linker]}
							keyField="CLIENTID_COURT"
							columns={[
								{
									dataField: 'FullName',
									text: 'KNOWN PERSON FULL NAME',
									formatter: trimFormatter,
								},
								{
									dataField: 'Gender',
									text: 'Gender',
									formatter: trimFormatter,
								},
								{
									dataField: 'DOB',
									text: 'Date of Birth',
									formatter: dateFormatter,
								},
							]}
						>
							{props => {
								return (
									<div>
										<BootstrapTable
											id="linkee-table"
											classes="button-table"
											bootstrap4={true}
											bordered={true}
											{...props.baseProps}
										/>
									</div>
								);
							}}
						</ToolkitProvider>
					</Col>
				</Row>
			</>
		);
	};

	const handleLink = () => {
		if (linker) {
			setLinking(false);
			setAlert(
				<ReactBSAlert
					warning
					style={{ display: 'block', marginTop: '150px' }}
					title="Link the following persons?"
					onConfirm={handleConfirmLink}
					onCancel={handleCancelLink}
					showCancel
					confirmBtnBsStyle="danger"
					confirmBtnText="Confirm Link"
					cancelBtnBsStyle="secondary"
					cancelBtnText="Cancel Link"
					btnSize=""
					focusCancelBtn
				>
					<LinkingTables linkee={linkee} linker={linker} />
					This action is irreversible!
				</ReactBSAlert>,
			);
		} else {
			setAlert(
				<ReactBSAlert
					danger
					style={{ display: 'block', marginTop: '150px' }}
					title="No known person selected"
					onConfirm={() => setAlert([])}
					onCancel={() => setAlert([])}
					confirmBtnBsStyle="info"
					btnSize=""
				>
					Please select a known person to link.
				</ReactBSAlert>,
			);
		}
	};

	const handleConfirmLink = () => {
		secureAPI(
			{
				endpoint: 'LINK',
				method: 'POST',
			},
			{
				Linkee: linkee['CLIENTID_COURT'],
				Linker: linker['CLIENTID_COURT'],
				responseType: 'json',
			},
		)
			.then(() => {})
			.catch(error => console.log(error));
		setOriginal(
			originalCourtlist.map(el => {
				if (el['CLIENTID_MAP'] === linkee['CLIENTID_MAP']) {
					el['CLIENTID_COURT'] = linker['CLIENTID_COURT'];
				}
				return el;
			}),
		);
		setLinking(false);
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '150px' }}
				title="Known Person linked!"
				onConfirm={handleCancelLink}
				onCancel={handleCancelLink}
				confirmBtnBsStyle="info"
				confirmBtnText="Ok"
				btnSize=""
			>
				<LinkingTables linkee={linkee} linker={linker} />
			</ReactBSAlert>,
		);
	};

	const handleCancelLink = () => {
		setAlert([]);
		setLinking(false);
		setLinker(false);
	};

	const getAge = birthdate => {
		if (birthdate === null || birthdate === undefined) {
			return null;
		}
		let start = formatDateAU(birthdate);
		let today = new Date().toLocaleDateString('en-AU');
		let end = formatDateAU(today);
		return end.diff(start, 'years');
	};

	useEffect(() => {
		let canSet = true;

		const customiseRows = rowObj => {
			rowObj['Court DET region'] = rowObj['Court DET region'] ? rowObj['Court DET region'] : 'N/A';
			rowObj['Court DET area'] = rowObj['Court DET area'] ? rowObj['Court DET area'] : 'N/A';
			rowObj['Age'] = getAge(rowObj['CONSOLIDATED_COURT']?.trim());
			rowObj['FirstAppearance'] =
				rowObj['FullName'] && (rowObj['FirstAppearance'] === 'Yes: ' || rowObj['FirstAppearance'] === 'No: ')
					? rowObj['FirstAppearance'] + rowObj['FullName']
					: rowObj['FirstAppearance'];
			return rowObj;
		};

		let courtCall = secureAPI(
			{
				endpoint: 'COURT',
				method: 'POST',
			},
			{
				ejiRole: props.user.attributes['custom:eji_role'],
				detRegion: props.user.attributes['custom:DETRegion'],
				responseType: 'json',
			},
		)
			.then(response => {
				let rows = response.data.rows;
				let columns = response.data.columns;
				let tableRows = compileTableRows(rows, columns, customiseRows);
				let cValue = props.user.attributes['custom:DETArea'];
				let courts = [];
				let dCourts = [];

				if (cValue !== null && cValue !== undefined && cValue.toUpperCase() !== 'ADMIN') {
					courts = cValue.split('|');
					dCourts = courts.map(data => ({ label: data, value: data }));
				}

				if (canSet) {
					const courtOptions = getSortedDistinct(tableRows, 'COURT');
					const areaOptions = getSortedDistinct(tableRows, 'Court DET area');
					const regionOptions = getSortedDistinct(tableRows, 'Court DET region');
					const courtroomOptions = getSortedDistinct(tableRows, 'COURTROOM NR');

					setSelectOptions({
						courts: courtOptions,
						areas: areaOptions,
						regions: regionOptions,
						courtrooms: courtroomOptions,
					});
					setDataTable(tableRows);
					setOriginal(tableRows);
					setActiveCourts(dCourts);
					courtFilter(courts);
				}
			})
			.catch(error => {
				console.log(error);
			});

		let knownCall = secureAPI({
			endpoint: 'KNOWNS',
			method: 'GET',
		})
			.then(response => {
				if (canSet) {
					response.data.map(data => {
						data['FullName'] = `${titleCase(data['FirstName'])} ${titleCase(data['LastName'])}`;
						return data;
					});
					setKnowns(response.data);
				}
			})
			.catch(error => {
				console.log(error);
			});

		Promise.all([courtCall, knownCall])
			.then(() => setLoading(false))
			.catch(error => console.log(error));

		return () => (canSet = false);
	}, [props.user.attributes]);

	useEffect(() => {
		let result = originalCourtlist;
		let canSet = true;

		if (typeof startDate === 'object') {
			let formattedStartDate = startDate.format();
			result = result.filter(el => moment(el['HEARING DATE']).format() >= formattedStartDate);
		}

		if (typeof endDate === 'object') {
			let formattedEndDate = endDate
				.clone()
				.add(1, 'days')
				.format();
			result = result.filter(el => moment(el['HEARING DATE']).format() <= formattedEndDate);
		}

		if (canSet) {
			setDataTable(result);
		}

		return () => (canSet = false);
	}, [startDate, endDate, originalCourtlist]);

	const copyToClipboardAsTable = el => {
		var body = document.body,
			range,
			sel;
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
				range.selectNodeContents(el);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(el);
				sel.addRange(range);
			}
			document.execCommand('copy');
		} else if (body.createTextRange) {
			range = body.createTextRange();
			range.moveToElementText(el);
			range.select();
			range.execCommand('Copy');
		}
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '0px' }}
				title="Good job!"
				onConfirm={() => setAlert([])}
				onCancel={() => setAlert([])}
				confirmBtnBsStyle="info"
				btnSize=""
			>
				Copied to clipboard!
			</ReactBSAlert>,
		);
	};

	const courtProfileEvent = {
		// set to onDoubleClick to avoid misclicking on tablet devices and allow copying of names etc
		onClick: (e, col, colIndex, row, rowIndex) =>
			history.push(`${props.baseRoute}/courtprofile/${row['CLIENTID_COURT']}`),
	};

	const rowSelectStyle = {
		backgroundColor: '#b5374f',
		color: '#fff',
		fontWeight: 'bold',
		fontStyle: 'italic',
	};

	const rowCourtStyle = (row, rowIndex) => {
		const style = {};
		const activeStatus = new Set(['Referral received', 'Active monitoring', 'Open', 'Active planning']);
		const isActive = activeStatus.has(row['CaseStatus']);
		if (row['FirstAppearance'] === 'Awaiting Verification') {
			style.backgroundColor = '#0000ff2e';
		} else if (row['FirstAppearance'] !== 'Yes' && !isActive) {
			style.backgroundColor = '#ffd60036';
		} else if (row['FirstAppearance'] !== 'Yes' && isActive) {
			style.backgroundColor = '#CCFFCC';
		}
		return style;
	};

	const Datepicker = (placeholder, stateFunc) => (
		<FormGroup>
			<InputGroup className="input-group-alternative">
				<InputGroupAddon addonType="prepend">
					<InputGroupText>
						<i className="ni ni-calendar-grid-58" />
					</InputGroupText>
				</InputGroupAddon>
				<ReactDatetime
					inputProps={{
						placeholder: placeholder,
					}}
					dateFormat={'DD MMM YYYY'}
					timeFormat={false}
					closeOnSelect={true}
					onChange={e => {
						if (typeof e === 'object') {
							// console.log(placeholder, e);
							stateFunc(e);
						} else {
							stateFunc('');
						}
					}}
				/>
			</InputGroup>
		</FormGroup>
	);

	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			width: state.selectProps.width,
			color: state.selectProps.menuColor,
			padding: 20,
		}),

		control: (base, state) => ({
			...base,
			boxShadow: state.isFocused ? 0 : 0,
			borderColor: state.isFocused ? '#b5374f' : base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? '#b5374f' : base.borderColor,
			},
		}),

		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		},
	};

	const GetMultiSelctions = selections => selections.map(selects => (selects ? selects.value : ''));

	const MultiSelectFilterLabel = props => (
		<Select
			styles={customStyles}
			isMulti
			placeholder={props.placeholder}
			onChange={e => {
				props.setterFunc(e);
				props.filterFunc(GetMultiSelctions(e));
				console.log(props.dv);
			}}
			options={Object.values(props.options).map((option, index) => ({ value: option, label: option }))}
			defaultValue={props.dv ? props.dv : undefined}
		/>
	);

	return (
		<>
			{alert}

			<Modal className="modal-dialog-centered" size="lg" toggle={() => setLinking(!isLinking)} isOpen={isLinking}>
				<div className="modal-header">
					<h5 className="modal-title" id="link-modal-title">
						Link to Known Person
					</h5>
				</div>
				<ModalBody>
					<Row>
						<Col>
							<Row>
								<Col className="text-center">Court appearance:</Col>
							</Row>
							<ToolkitProvider
								data={[linkee]}
								keyField="CLIENTID_MAP"
								columns={[
									{
										dataField: 'FULL NAME',
										text: 'ACCUSED FULL NAME',
										formatter: trimFormatter,
										headerStyle: (col, colIndex) => {
											return { width: '400px' };
										},
									},
									{
										dataField: 'GENDER',
										text: 'Gender',
										formatter: trimFormatter,
										headerStyle: (col, colIndex) => {
											return { width: '140px' };
										},
									},
									{
										dataField: 'CONSOLIDATED_COURT',
										text: 'Date of Birth',
										formatter: trimFormatter,
										headerStyle: (col, colIndex) => {
											return { width: '210px' };
										},
									},
								]}
							>
								{props => {
									return (
										<div>
											<BootstrapTable
												id="linkee-table"
												classes="button-table"
												bootstrap4={true}
												bordered={true}
												rowStyle={rowSelectStyle}
												{...props.baseProps}
											/>
										</div>
									);
								}}
							</ToolkitProvider>
						</Col>
					</Row>
					<Row>
						<Col className="text-center">Links to Known Person:</Col>
					</Row>
					<Row>
						<Col>
							<ToolkitProvider
								data={knowns}
								keyField="CLIENTID_COURT"
								search={{
									searchFormatted: false,
									defaultSearch: linkee['exactMatch'],
								}}
								columns={[
									{
										dataField: 'CLIENTID_COURT',
										text: 'Young Person ID',
										sort: true,
										hidden: true,
									},
									{
										dataField: 'FullName',
										text: 'Name',
										sort: true,
										headerStyle: (col, colIndex) => {
											return { width: '400px' };
										},
									},
									{
										dataField: 'Gender',
										text: 'Gender',
										sort: true,
										headerStyle: (col, colIndex) => {
											return { width: '140px' };
										},
									},
									{
										dataField: 'DOB',
										text: 'Date of Birth',
										sort: true,
										formatter: dateFormatter,
										headerStyle: (col, colIndex) => {
											return { width: '210px' };
										},
									},
									{
										dataField: 'FirstName',
										text: 'First Name',
										hidden: true,
									},
									{
										dataField: 'LastName',
										text: 'Last Name',
										hidden: true,
									},
								]}
							>
								{props => {
									return (
										<div className="py-4 table-responsive">
											<Container fluid>
												<Row>
													<Col xs={12} sm={6}></Col>
													<Col xs={12} sm={6}>
														<div
															id="datatable-basic_filter"
															className="dataTables_filter pb-1 float-right"
														>
															<label>
																Search:
																<SearchBar
																	className="form-control-sm"
																	placeholder=""
																	{...props.searchProps}
																/>
															</label>
														</div>
													</Col>
												</Row>
											</Container>
											<BootstrapTable
												id="linker-table"
												classes="button-table"
												bootstrap4={true}
												bordered={true}
												pagination={pagination}
												selectRow={{
													mode: 'radio',
													clickToSelect: true,
													hideSelectColumn: true,
													style: rowSelectStyle,
													onSelect: handleOnSelect,
												}}
												noDataIndication={() => <KPNoDataIndication />}
												{...props.baseProps}
											/>
										</div>
									);
								}}
							</ToolkitProvider>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Col>
						<Button color="primary" type="button" onClick={handleLink}>
							Link
						</Button>
					</Col>
					<Col className="text-md-right">
						<Button color="warning" onClick={handleCancelLink} type="button">
							Cancel
						</Button>
					</Col>
				</ModalFooter>
			</Modal>

			<Container className="court-list-table mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<Container fluid>
								<Row className="mt-4">
									<Col sm={12} md={6} lg={4}>
										<Col>{Datepicker('Start Date', setStartDate)}</Col>
										<Col>{Datepicker('End Date', setEndDate)}</Col>
									</Col>
									<Col sm={12} md={6} lg={8}>
										<Row className="justify-content-end">
											<Col>
												<div
													id="datatable-select_filter"
													className="dataTables_filter pb-1 float-right"
												>
													<MultiSelectFilterLabel
														placeholder="Court | Please select court(s)"
														options={selectOptions.courts}
														dv={activeCourts}
														setterFunc={setActiveCourts}
														filterFunc={courtFilter}
													/>
												</div>
											</Col>
										</Row>
										<Row className="justify-content-end">
											<Col>
												<div
													id="datatable-select_filter"
													className="dataTables_filter pb-1 float-right"
												>
													<MultiSelectFilterLabel
														placeholder="Court Room | Please select court room(s)"
														options={selectOptions.courtrooms}
														dv={activeCourtrooms}
														setterFunc={setActiveCourtrooms}
														filterFunc={courtroomFilter}
													/>
												</div>
											</Col>
										</Row>
										<Row className="justify-content-end">
											<Col>
												<div
													id="datatable-select_filter"
													className="dataTables_filter pb-1 float-right"
												>
													<MultiSelectFilterLabel
														name="Area:"
														placeholder="Area | Please select area(s)"
														options={selectOptions.areas}
														dv={activeAreas}
														setterFunc={setActiveAreas}
														filterFunc={areaFilter}
													/>
												</div>
											</Col>
										</Row>
										<Row className="justify-content-end">
											<Col>
												<div
													id="datatable-select_filter"
													className="dataTables_filter pb-1 float-right"
												>
													<MultiSelectFilterLabel
														placeholder="Region | Please select region(s)"
														options={selectOptions.regions}
														dv={activeRegions}
														setterFunc={setActiveRegions}
														filterFunc={regionFilter}
													/>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</Container>
							<ToolkitProvider
								data={dataTable}
								keyField="CLIENTID_MAP"
								search={{
									searchFormatted: false,
								}}
								columns={[
									{
										dataField: '',
										text: 'Action',
										sort: false,
										classes: 'button-cell',
										formatter: optionsFormatter,
									},
									{
										dataField: 'HEARING DATE',
										text: 'Hearing date',
										sort: true,
										formatter: dateFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'FULL NAME',
										text: 'FULL NAME',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'CONSOLIDATED_COURT',
										text: 'DOB',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'Age',
										text: 'Age',
										sort: true,
										events: courtProfileEvent,
									},
									{
										dataField: 'GENDER',
										text: 'Gender',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'Enrolled School',
										text: 'Enrolled School',
										sort: true,
										events: courtProfileEvent,
										formatter: schoolExitIconFormatter,
									},
									{
										dataField: 'Enrolled Exit Date',
										text: 'Exited School',
										sort: true,
										formatter: schoolExitFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'EarlyEducationInterventionFlag',
										text: 'EEIO',
										sort: true,
										events: courtProfileEvent,
									},
									{
										dataField: 'CUSTODY(Y/ )',
										text: 'Custody (Y/ )',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'CLIENTID_COURT',
										text: 'Young person ID',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'COURT',
										text: 'Court location',
										sort: true,
										formatter: trimFormatter,
										filter: multiSelectFilter({
											options: selectOptions.courts,
											getFilter: filter => {
												courtFilter = filter;
											},
										}),
										events: courtProfileEvent,
									},
									{
										dataField: 'COURTROOM NR',
										text: 'Courtroom number',
										sort: true,
										formatter: trimFormatter,
										filter: multiSelectFilter({
											options: selectOptions.courtrooms,
											getFilter: filter => {
												courtroomFilter = filter;
											},
										}),
										events: courtProfileEvent,
									},
									{
										dataField: 'HRG TYPE',
										text: 'Hearing type',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'FirstAppearance',
										text: 'First Appearance',
										sort: true,
										events: courtProfileEvent,
									},
									{
										dataField: 'Court DET area',
										text: 'Court DET Area',
										sort: true,
										formatter: trimFormatter,
										filter: multiSelectFilter({
											options: selectOptions.areas,
											getFilter: filter => {
												areaFilter = filter;
											},
										}),
										events: courtProfileEvent,
									},
									{
										dataField: 'Court DET region',
										text: 'Court DET Region',
										sort: true,
										formatter: trimFormatter,
										filter: multiSelectFilter({
											options: selectOptions.regions,
											getFilter: filter => {
												regionFilter = filter;
											},
										}),
										events: courtProfileEvent,
									},
									{
										dataField: 'TIME',
										text: 'Hearing time',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'CASE NR',
										text: 'Case number',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'AGENCY',
										text: 'Prosecuting agency',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'INFT/RESP',
										text: 'INFT/RESP',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'BARRISTER/SOLICITOR',
										text: 'Barrister/solicitor',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'PART HRD',
										text: 'Part heard',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'AJD REQEST',
										text: 'ADJ request',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'REPORT REQUEST',
										text: 'Report request',
										sort: true,
										formatter: trimFormatter,
										events: courtProfileEvent,
									},
									{
										dataField: 'Match_final',
										text: 'Verification status',
										sort: true,
										hidden: true,
									},
									{
										dataField: 'FIRST NAME',
										text: 'First Name',
										hidden: true,
									},
									{
										dataField: 'LAST NAME',
										text: 'Last Name',
										hidden: true,
									},
								]}
							>
								{props => {
									return (
										<div className="py-4 table-responsive">
											<Container fluid>
												<Row>
													<Col xs={12} sm={12} md={6}>
														<div
															id="datatable-basic_filter"
															className="dataTables_filter pb-1 float-left"
														>
															<label>
																Search:
																<SearchBar
																	className="form-control-sm"
																	placeholder=""
																	{...props.searchProps}
																/>
															</label>
														</div>
													</Col>
													<Col xs={12} sm={12} md={6}>
														<div className="pb-1 float-right">
															<Button
																className="buttons-copy buttons-html5"
																color="default"
																size="sm"
																id="copy-tooltip"
																onClick={() =>
																	copyToClipboardAsTable(
																		document.getElementById('court-list-table'),
																	)
																}
															>
																<span>
																	<i
																		className="fas fa-copy"
																		style={{ margin: '0 10px 0 0' }}
																	></i>
																	Copy
																</span>
															</Button>
															<ReactToPrint
																trigger={() => (
																	<Button
																		color="default"
																		size="sm"
																		className="buttons-copy buttons-html5"
																		id="print-tooltip"
																	>
																		<span>
																			<i
																				className="fas fa-print"
																				style={{ margin: '0 10px 0 0' }}
																			></i>
																			Print
																		</span>
																	</Button>
																)}
																content={() => tableRef.current}
															/>
															<UncontrolledTooltip placement="top" target="print-tooltip">
																This will open a print page with the visible rows of the
																table.
															</UncontrolledTooltip>
															<UncontrolledTooltip placement="top" target="copy-tooltip">
																This will copy to your clipboard the visible rows of the
																table.
															</UncontrolledTooltip>
														</div>
													</Col>
												</Row>
											</Container>
											<BootstrapTable
												id="court-list-table"
												ref={tableRef}
												classes="button-table"
												bootstrap4={true}
												bordered={false}
												pagination={pagination}
												filter={filterFactory()}
												noDataIndication={() => <NoDataIndication />}
												rowStyle={rowCourtStyle}
												{...props.baseProps}
											/>
										</div>
									);
								}}
							</ToolkitProvider>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default CourtListTable;
